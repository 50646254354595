var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"fluid":"","src":_vm.$root.appLogo,"alt":"Login V2"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"src":_vm.imgUrl,"fluid":"","alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('title_password_reset'))+" 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('label_pass_reset_desc'))+" ")]),_c('validation-observer',{ref:"simpleRules",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label":_vm.$t('label_new_password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('label_new_password'),"vid":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"placeholder":"············"},model:{value:(_vm.itemData.password),callback:function ($$v) {_vm.$set(_vm.itemData, "password", $$v)},expression:"itemData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('label_confirm_password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('label_confirm_password'),"rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.itemData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.itemData, "confirmPassword", $$v)},expression:"itemData.confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"block":"","type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit);_vm.focusOnFormError(invalid)}}},[_vm._v(" "+_vm._s(_vm.$t('label_save'))+" ")])]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login-v2'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('label_back_to') + ' login')+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }